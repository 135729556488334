import React, { useState } from "react";
import { connect } from "react-redux";

import Helmet from 'react-helmet';

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import maTheme from "./theme";
import Routes from "./routes/Routes";

import { AppContextProvider } from './utils/AppContext'

import config from './config';

import { withAuthenticator } from '@aws-amplify/ui-react';


// set dev default to true if you want to skip login during development
let defaultAuthState = process.env.NODE_ENV === "development" ? true : false

// setup app context 'appState' object, to be used by AppContext
let appState = {
  email: '',
  userHasAuthenticated: defaultAuthState,
}

function App({ theme }) {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate={`%s | ${config.appProperties.appName}`}
        defaultTitle={`${config.appProperties.company} ${config.appProperties.appName}`}
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <AppContextProvider stateObj={appState}>
                {/* <AmplifySignOut /> */}
                <Routes />
              </AppContextProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default withAuthenticator(connect(store => ({ theme: store.themeReducer }))(App));
