export default {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://lndbvfw6v7.execute-api.us-west-2.amazonaws.com"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_ry53RNPFa",
    APP_CLIENT_ID: "4qk5vfbgf1jdc7bdqk5afh1k4v",
    IDENTITY_POOL_ID: "us-west-2:4a217f8b-8e48-4bb6-97a7-dcbc4e5fe1a4"
  },
  appProperties: {
    company: "BigPanda",
    appName: "SE Bootcamp Lab"
  }
};