import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { darken } from "polished";
// import logo from "../assets/camopanda.png"
import { useAppContext } from '../utils/AppContext'
import { AmplifySignOut } from '@aws-amplify/ui-react';

import {
  Badge,
  Grid,
  Hidden,
  InputBase,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

import {
  Bell,
  MessageSquare,
  Search as SearchIcon,
  Power
} from "react-feather";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${props => props.theme.header.indicator.background};
    color: ${props => props.theme.palette.common.white};
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${props => darken(0.05, props.theme.header.background)};
  }

  ${props => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${props => props.theme.header.search.color};
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    padding-left: ${props => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

// const Logo = styled.img`
//   border-radius: 50%;
//   width: 44px;
//   height: 44px;
// `;

function LanguageMenu() {
  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Flag src="/static/img/flags/us.png" alt="English" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>
          English
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          French
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          German
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          Dutch
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

function UserMenu() {
  let { appState, setAppState } = useAppContext();
  const history = useHistory();

  const [anchorMenu, setAnchorMenu] = useState(null);

  const toggleMenu = event => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleLogout = () => {
    setAppState({
      ...appState,
      userHasAuthenticated: false
    })
    history.push("/");
  }
  return (
    <React.Fragment>
      <AmplifySignOut />
      {/* <IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Power />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      > */}
      {/* {appState.userHasAuthenticated === false ?
          [
            <MenuItem
              key="signin"
              component={Link}
              to="/auth/sign-in"
            >
              Sign in
          </MenuItem>,
            <MenuItem
              key="signup"
              component={Link}
              to="/auth/sign-up"
            >
              Sign up
          </MenuItem>
          ] :
          [
            // <MenuItem
            //   key="profile"
            //   component={Link}
            //   to="/profile"
            // >
            //   Profile
            // </MenuItem>,
            <MenuItem
              key="logout"
              onClick={handleLogout}
            >
              Sign out
            </MenuItem>
          ]
        }
      </Menu> */}
    </React.Fragment >
  );
}

const Header = ({ onDrawerToggle }) => {
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            {/* {<Logo src={logo} alt="Logo" />} */}
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder="Search topics" />
              </Search>
            </Grid>
            <Grid item xs ></Grid>
            <Grid item>
              {/* <IconButton color="inherit">
                <Indicator badgeContent={3}>
                  <MessageSquare />
                </Indicator>
              </IconButton>
              <IconButton color="inherit">
                <Indicator badgeContent={7}>
                  <Bell />
                </Indicator>
              </IconButton>
              <LanguageMenu /> */}
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
};

export default connect()(withTheme(Header));
