import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes, authRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";

import { useAppContext } from '../utils/AppContext'



const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ name, path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} name={name} />
            </Layout>
          )}
        />
      ))
    ) : (
        // Route item without children
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      )
  );

const Routes = () => {
  let { appState } = useAppContext();
  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {/* {appState.userHasAuthenticated ?
          childRoutes(DashboardLayout, dashboardRoutes) :
          childRoutes(AuthLayout, authRoutes)
        } */}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  )
};

export default Routes;
