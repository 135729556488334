import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Sliders,
  User,
  List
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));

// Lab pages
const Lab = async(() => import("../pages/labs/Lab"))

// Pages components
const Profile = async(() => import("../pages/profile/Profile"));
const UploadScenario = async(() => import("../pages/admin/UploadScenario"))
const EditScenario = async(() => import("../pages/admin/EditScenario"))

// Documentation
const Docs = async(() => import("../pages/docs/Documentation"));

const ApiTests = async(() => import("../pages/apiTest/apiTest"))

const documentationRoutes = {
  id: "Getting Started",
  path: "/",
  header: "Docs",
  icon: <BookOpen />,
  component: Docs,
  children: null
};

const adminRoutes = {
  id: "Admin",
  path: "/admin",
  icon: <List />,
  children: [
    {
      path: "/admin/upload-scenario",
      name: "Upload Scenario",
      component: UploadScenario
    },
    {
      path: "/admin/edit-scenario",
      name: "Edit Scenario",
      component: EditScenario
    }
  ]
};

const labRoutes = {
  id: "Labs",
  path: "/labs",
  header: "Dashboard",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/labs/AlertInjector",
      name: "Alert Injector",
      component: () => {
        return <Lab
          name="Alert Injector"
          path="/labs/AlertInjector"
          scenarioFile1="lab_alerts_splunk.csv" integName1="Splunk"
          scenarioFile2="lab_alerts_spectrum.csv" integName2="Spectrum"
          scenarioFile3="lab_alerts_newrelic.csv" integName3="NewRelic"
          scenarioFile4="lab_alerts_scom.csv" integName4="SCOM"
        />
      },
    },
  ]
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <User />,
  component: Profile,
  children: null
};

const apiTestRoutes = {
  id: "ApiTests",
  path: "/apiTests",
  icon: <User />,
  component: ApiTests,
  children: null
};

const unauthDefaultRoute = {
  id: "unauthDefaultRoute",
  path: "/",
  name: "Sign In",
  component: SignIn,
  icon: <User />,
  children: null
};

const signInRoute = {
  id: "signInRoute",
  path: "/auth/sign-in",
  name: "Sign In",
  component: SignIn,
  icon: <User />,
  children: null
};

const signUpRoute = {
  id: "signUpRoute",
  path: "/auth/sign-up",
  name: "Sign Up",
  component: SignUp,
  icon: <User />,
  children: null
};

const resetPwRoute = {
  id: "resetPwRoute",
  path: "/auth/reset-password",
  name: "Reset Password",
  component: ResetPassword,
  icon: <User />,
  children: null
};

// used by Routes.js to build paths
export const dashboard = [
  documentationRoutes,
  labRoutes,
  // profileRoutes,
  // apiTestRoutes,
  // adminRoutes
];

export const authRoutes = [
  unauthDefaultRoute,
  signInRoute,
  signUpRoute,
  resetPwRoute
];

// used by Sidebar.js to display the routes
export default [
  documentationRoutes,
  labRoutes,
  // profileRoutes,
  // apiTestRoutes,
  // adminRoutes,
];
