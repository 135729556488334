import React, { useState } from 'react';

// Signed-in appState context
const AppContext = React.createContext();

function AppContextProvider({ stateObj, children }) {
  const [appState, setAppState] = useState({
    ...stateObj
  });


  AppContext.displayName = 'AppContext';
  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppContext.Provider>
  )
}

function useAppContext() {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error('UseAppContext must be used within a AppContextProvider')
  }
  return context
}

export { AppContextProvider, useAppContext }
